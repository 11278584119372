import React from 'react';
import PropTypes from 'prop-types';

const NewsletterOption = ({
  className,
  onChange,
  isChecked,
  imgUrl,
  imgAlt,
  description,
  frequency
}) => (
  <section className={className}>
    <div className="timeFrame">
      <p>{frequency}</p>
      <label className="inputgroup">
        <input type="checkbox" checked={isChecked} onChange={onChange} />
        <span className="checkbox" />
      </label>
    </div>
    <img src={imgUrl} alt={imgAlt} />
    <p
      className="description"
      dangerouslySetInnerHTML={{
        __html: description
      }}
    />
  </section>
);

NewsletterOption.defaultProps = {
  className: undefined
};

NewsletterOption.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  imgUrl: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  frequency: PropTypes.string.isRequired
};

export default NewsletterOption;
