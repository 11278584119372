import React, { useState } from 'react';
import { Section } from '../../../../fc-components/main/components';
import MarketoForm from '../../../../components/MarketoForm/MarketoForm';
import PropTypes from 'prop-types';
import NewsletterOption from './NewsletterOption';

const MarketoNewsletterForm = ({ handleSubmission }) => {
  const [checkedCompass, setCheckCompass] = useState(true);
  const [checkedCodesign, setCheckCodesign] = useState(true);
  const [checkedImpact, setCheckImpact] = useState(true);
  const [checkedPlugged, setCheckPlugged] = useState(true);
  const [checkedModernCeo, setCheckedModernCeo] = useState(true);
  const [checkedTopTen, setCheckedTopTen] = useState(true);
  const [checkedWorkSmarter, setCheckedWorkSmarter] = useState(true);
  const [checkedAIDecoded, setCheckedAIDecoded] = useState(true);
  const [checkedPodcasts, setCheckedPodcasts] = useState(true);
  const [checkedSpotlight, setCheckedSpotlight] = useState(true);
  const [checkedTopTech, setCheckTopTech] = useState(true);

  const handleCheckCompass = () => {
    setCheckCompass(prev => !prev);
    const marketoInput = document.getElementsByName(
      'formOptInFCCompassNewsletter'
    )[0];
    if (!marketoInput) return;

    if (checkedCompass) {
      marketoInput.checked = false;
    } else {
      marketoInput.checked = true;
    }
  };

  const handleCheckTopTech = () => {
    setCheckTopTech(prev => !prev);
    const marketoInput = document.getElementsByName('optInFCTechNewsletter')[0];
    if (!marketoInput) return;

    if (checkedTopTech) {
      marketoInput.checked = false;
    } else {
      marketoInput.checked = true;
    }
  };

  const handleCheckCodesign = () => {
    setCheckCodesign(prev => !prev);
    const marketoInput = document.getElementsByName(
      'formOptInFCCoDesignNewsletter'
    )[0];
    if (!marketoInput) return;

    if (checkedCodesign) {
      marketoInput.checked = false;
    } else {
      marketoInput.checked = true;
    }
  };

  const handleCheckImpact = () => {
    setCheckImpact(prev => !prev);
    const marketoInput = document.getElementsByName(
      'formOptInFCImpactNewsletter'
    )[0];
    if (!marketoInput) return;

    if (checkedImpact) {
      marketoInput.checked = false;
    } else {
      marketoInput.checked = true;
    }
  };

  const handleCheckPlugged = () => {
    setCheckPlugged(prev => !prev);
    const marketoInput = document.getElementsByName(
      'formOptInFCPluggedInNewsletter'
    )[0];
    if (!marketoInput.length === 0) return;

    if (checkedPlugged) {
      marketoInput.checked = false;
    } else {
      marketoInput.checked = true;
    }
  };

  const handleAIDecoded = () => {
    setCheckedAIDecoded(prev => !prev);
    const marketoInput = document.getElementsByName(
      'formOptInFCAIDecdedNewsletter optInFCAIDecodedNewsletter'
    )[0];
    if (!marketoInput.length === 0) return;

    if (checkedPlugged) {
      marketoInput.checked = false;
    } else {
      marketoInput.checked = true;
    }
  };

  const handleCheckModernCeo = () => {
    setCheckedModernCeo(prev => !prev);
    const marketoInput = document.getElementsByName(
      'formOptInFCModernCEONewsletter'
    )[0];
    if (!marketoInput) return;

    if (checkedModernCeo) {
      marketoInput.checked = false;
    } else {
      marketoInput.checked = true;
    }
  };

  const handleCheckTopTen = () => {
    setCheckedTopTen(prev => !prev);
    const marketoInput = document.getElementsByName(
      'formOptInFCTop10Newsletter'
    )[0];
    if (!marketoInput) return;

    if (checkedTopTen) {
      marketoInput.checked = false;
    } else {
      marketoInput.checked = true;
    }
  };

  const handleCheckSpotlight = () => {
    setCheckedSpotlight(prev => !prev);
    const marketoInput = document.getElementsByName(
      'formOptInFCTop10Newsletter'
    )[0];
    if (!marketoInput) return;

    if (checkedSpotlight) {
      marketoInput.checked = false;
    } else {
      marketoInput.checked = true;
    }
  };

  const handleCheckWorkSmarter = () => {
    setCheckedWorkSmarter(prev => !prev);
    const marketoInput = document.getElementsByName(
      'formOptInFCWorkLifeNewsletter'
    )[0];
    if (!marketoInput) return;

    if (checkedWorkSmarter) {
      marketoInput.checked = false;
    } else {
      marketoInput.checked = true;
    }
  };

  const handleCheckPodcasts = () => {
    setCheckedPodcasts(prev => !prev);
    const marketoInput = document.getElementsByName(
      'formOptInFCPodcastVideoNewsletter'
    )[0];
    if (!marketoInput) return;

    if (checkedPodcasts) {
      marketoInput.checked = false;
    } else {
      marketoInput.checked = true;
    }
  };

  return (
    <Section bgColor="#F4F4F4" noPadding>
      <div className="newsletters__page__marketo">
        <div className="newsletters__page__marketo__form__container">
          <div className="options">
            <NewsletterOption
              onChange={handleCheckCompass}
              isChecked={checkedCompass}
              imgUrl="https://assets.fastcompany.com/image/upload/v1670274719/fc-newsletter-logo.svg"
              imgAlt="Compass Newsletter"
              description="Fast Company’s trending stories delivered to you daily"
              frequency="Daily"
            />
            <NewsletterOption
              onChange={handleCheckTopTech}
              isChecked={checkedTopTech}
              imgUrl="https://assets.fastcompany.com/image/upload/v1713898312/toptech-logo-popcolor_2x.png"
              imgAlt="Top Tech Stories"
              description="Weekly roundup of the latest in tech news"
              frequency="Weekly"
            />
            <NewsletterOption
              onChange={handleCheckCodesign}
              isChecked={checkedCodesign}
              imgUrl="https://assets.fastcompany.com/image/upload/v1721839448/Co.Design_Logo_Redesign_v5_1.png"
              imgAlt="CoDesign Newsletter"
              description="The latest innovations in design brought to you every weekday"
              frequency="Weekdays"
            />
            <NewsletterOption
              className="ai-decoded"
              onChange={handleAIDecoded}
              isChecked={checkedAIDecoded}
              imgUrl="https://assets.fastcompany.com/image/upload/v1705678917/aiDecoded_Logo_horizontal_outlined.svg"
              imgAlt="AI Decoded Newsletter"
              description="Making sense of the rapidly evolving world of generative AI. "
              frequency="Weekly"
            />
            <NewsletterOption
              className="spotlight"
              onChange={handleCheckSpotlight}
              isChecked={checkedSpotlight}
              imgUrl="https://assets.fastcompany.com/image/upload/v1710775186/newsletters/fc-spotlight-newsletter-logo1.svg"
              imgAlt="FastCompany Spotlight Newsletter logo"
              description="This week's most pertinent read, selected by our editors"
              frequency="Weekly"
            />
            <NewsletterOption
              className="work-smarter"
              onChange={handleCheckWorkSmarter}
              isChecked={checkedWorkSmarter}
              imgUrl="https://assets.fastcompany.com/image/upload/v1691431471/newsletters/fc-worksmarter-logo2.svg"
              imgAlt="Work Smarter Newsletter"
              description="Career, leadership, and productivity advice, curated by our editors"
              frequency="Weekly"
            />
            <NewsletterOption
              className="modern-ceo"
              onChange={handleCheckModernCeo}
              isChecked={checkedModernCeo}
              imgUrl="https://assets.fastcompany.com/image/upload/v1680285557/newsletters/modernCEOBlack.svg"
              imgAlt="Modern CEO Newsletter"
              description="A refreshed look at leadership from CEO and chief content officer Stephanie Mehta"
              frequency="Weekly"
            />
            <NewsletterOption
              onChange={handleCheckImpact}
              isChecked={checkedImpact}
              imgUrl="https://assets.fastcompany.com/image/upload/v1670274672/fc-newsletter-logo_impact.svg"
              imgAlt="Impact Newsletter"
              description="A weekly look at the people and ideas making an impact in society and business"
              frequency="Weekly"
            />
            <NewsletterOption
              onChange={handleCheckPlugged}
              isChecked={checkedPlugged}
              imgUrl="https://assets.fastcompany.com/image/upload/v1670274702/fc-newsletter-logo_pluggedin.svg"
              imgAlt="Plugged In Newsletter"
              description="Tech insights, news, and trends from global technology editor Harry McCracken"
              frequency="Weekly"
            />
            <NewsletterOption
              className="podcasts"
              onChange={handleCheckPodcasts}
              isChecked={checkedPodcasts}
              imgUrl="https://assets.fastcompany.com/image/upload/v1691431852/newsletters/fc-podcasts-logo.svg"
              imgAlt="Podcasts Newsletter"
              description="The most fascinating topics and trends in culture covered by our podcast team"
              frequency="Monthly"
            />
            <NewsletterOption
              className="top-ten"
              onChange={handleCheckTopTen}
              isChecked={checkedTopTen}
              imgUrl="https://assets.fastcompany.com/image/upload/v1680283662/newsletters/fcTopTen.svg"
              imgAlt="FastCompany Top10 Newsletter logo"
              description="Fast Company’s Top 10 trending stories of the week, delivered to you each Sunday"
              frequency="Weekly"
            />
          </div>
          <MarketoForm assetId={1363} onSubmitHandler={handleSubmission} />
        </div>
      </div>
    </Section>
  );
};

export default MarketoNewsletterForm;

MarketoNewsletterForm.propTypes = {
  handleSubmission: PropTypes.func.isRequired
};
